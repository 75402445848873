import { useNotificationContext } from 'contexts';
import React, { useEffect, useState } from 'react';
import { getAllItems, getQuickProducts } from 'service';
import { genericMessageHandler, usePromise } from 'shared/helpers';

export const RECENTLY_ADDED_KEYS = {
    PRODUCT : 'product_recently_added',
    HARDWARE : 'hardware_recently_added'
};

export const useRecentlyAddedActions = () => {
    const addRecentItem = (itemId, type) => {
        const recentItemsString = localStorage.getItem(type);
        let existingItems = [];

        if (recentItemsString != '' && recentItemsString != null) {
            existingItems = JSON.parse(recentItemsString);
        }

        if (!existingItems.includes(itemId)) {
            existingItems.unshift(itemId);

            if ( existingItems.length > 10 ) existingItems.pop();
        } else {
            existingItems.splice(existingItems.indexOf(itemId), 1);
            existingItems.unshift( itemId );
        }

        localStorage.setItem(type, JSON.stringify(existingItems));
    }

    return {
        addRecentItem
    }
}

export const useReventlyAdded = (isProduct, setError) => {
    const [items, setItems] = useState([]);
    const { notify } = useNotificationContext();

    useEffect(() => {
        const items = localStorage.getItem( isProduct ? RECENTLY_ADDED_KEYS.PRODUCT : RECENTLY_ADDED_KEYS.HARDWARE );
        let recentlyAdded = [];

        if (items != '' && items != null) {
            recentlyAdded = JSON.parse(items);
        }

        if (recentlyAdded.length) {
            const promise = isProduct ? getQuickProducts() : getAllItems();

            return usePromise(
                ([products]) => {
                    if (products && products.length) {
                        products = products
                            .filter((product) =>
                                recentlyAdded.includes(product.id)
                            )
                            .sort((productA, productB) =>
                                productA.text.localeCompare(productB.text)
                            );

                        if (isProduct) {
                            setItems(
                                products.map((product) => {
                                    const productInfo = {
                                        ...product,
                                        ...{
                                            imageUrl: product.image,
                                        },
                                    };

                                    if (!productInfo.hasOwnProperty('name')) {
                                        productInfo.name = product.text;
                                    }

                                    return productInfo;
                                })
                            );
                        } else {
                            setItems(products);
                        }
                    }
                },
                [ promise ],
                (error, isCancelled) => {
                    genericMessageHandler(notify, error);
                }
            );
        }
    }, []);
    
    return {
        items
    }
}