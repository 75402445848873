import React, {useState, useEffect, useMemo} from 'react';
import {useConfirmationDialog} from 'shared';
import {useAppContext, useJobContext, useNotificationContext} from 'contexts';
import {
    getNotesForCustomAddress,
    saveJob,
    submitJob,
    getQuickProducts,
    deleteRoom,
} from 'service';
import {formatPrice, genericMessageHandler, usePromise} from 'shared/helpers';
import {useNavigate, useParams} from 'react-router-dom';
import {useFetchBenchesData} from 'components/customer/BTM/helper/useFetchBenchesData';
import {useAppDispatch} from 'store/customer';
import {sendAnalyticsEvent} from 'shared/helpers/Analytics';
import {invalidateProductAndCost} from 'components/customer/Product/store/productApi';
import {
    invalidateJob,
    useGetJobStatusStatisticsQuery,
} from 'components/customer/Job/store/jobApi';
import {invalidateDeliveryAddress} from 'components/customer/Settings/store/settingsApi';
import {JobRedirectEnum} from 'components/manufacturer/Preferences/entity/Preferences';
import {BaseConfig} from 'config';
import excel from 'shared/Excel';
import {JobStatus, JobStatusFilter} from 'shared/JobStatus';

export const DispatchMethod = {
    PICKUP: 0,
    FREIGHT_DELIVERY_ADDRESS: 2,
    FREIGHT_CUSTOM_ADDRESS: 1,
    FREIGHT_TO_CLIENT_ADDRESS: 3,
};

const config = BaseConfig['USER_CUSTOMER'];
const CreateJobRedirect = {
    [JobRedirectEnum.PRODUCT]: {
        path: 'product',
        isVisible: config.jobMenu.find((menu) => menu.id === 'add_product')
            .isVisible,
    },
    [JobRedirectEnum.FAVOURITES]: {
        path: 'favourites',
        isVisible: config.jobMenu.find(
            (menu) => menu.id === 'favourite_products'
        ).isVisible,
    },
    [JobRedirectEnum.SUNDRIES]: {
        path: 'hardware',
        isVisible: config.jobMenu.find((menu) => menu.id === 'sundry')
            .isVisible,
    },
    [JobRedirectEnum.LAYOUT]: {
        path: 'layout',
        isVisible: config.jobMenu.find((menu) => menu.id === 'tdld').isVisible,
    },
    [JobRedirectEnum.DETAILED_CART]: {
        path: 'dashboard',
        isVisible: true,
    },
    [JobRedirectEnum.QFP]: {
        path: 'quick-flat-product',
        isVisible: config.jobMenu.find((menu) => menu.id === 'qfp').isVisible,
    },
};

const getCreateJobRedirect = (userProfile, jobId, roomId) => {
    const {
        createJobLandingPage,
        isBenchtopAvailable,
        isBTDAvailable,
        isAddProductAvailable,
    } = userProfile;

    if (createJobLandingPage === JobRedirectEnum.BENCHTOP) {
        // only one will be active for benchtop, see user api for details
        if (isBenchtopAvailable) {
            return `/v2/job/${jobId}/benchtop-module`;
        } else if (isBTDAvailable && roomId !== '') {
            return `/v2/job/${jobId}/room/${roomId}/benchtop-designer`;
        }
    }

    if (roomId === '') {
        // return to dashboard by default if no room id
        return `/v2/job/${jobId}/dashboard`;
    }

    if (
        createJobLandingPage !== JobRedirectEnum.BENCHTOP &&
        createJobLandingPage in JobRedirectEnum
    ) {
        const {path, isVisible} = CreateJobRedirect[createJobLandingPage];
        const moduleAvailable =
            typeof isVisible !== 'boolean'
                ? excel.calculate(isVisible, userProfile)
                : isVisible;
        if (
            [JobRedirectEnum.SUNDRIES, JobRedirectEnum.DETAILED_CART].includes(
                createJobLandingPage
            ) &&
            moduleAvailable
        ) {
            return `/v2/job/${jobId}/${path}`;
        }

        if (moduleAvailable) {
            return `/v2/job/${jobId}/room/${roomId}/${path}`;
        }
    }

    if (!isAddProductAvailable) {
        return `/v2/job/${jobId}/dashboard`;
    }

    // default to product if available
    return `/v2/job/${jobId}/room/${roomId}/product`;
};

export const useFreightNotes = (
    dispatchMethod = DispatchMethod.FREIGHT_CUSTOM_ADDRESS
) => {
    const {notify} = useNotificationContext();
    const [type, setType] = useState(dispatchMethod);
    const [notes, setNotes] = useState([]);

    useEffect(() => {
        const promise = getNotesForCustomAddress(true);

        return usePromise(
            ([notes]) => {
                setNotes(notes);
            },
            [promise],
            (error) => {
                genericMessageHandler(notify, error);
            }
        );
    }, [type]);

    return {setType, notes};
};

export const useJobFormSubmitHandler = (jobId) => {
    const dispatch = useAppDispatch();
    const {notify} = useNotificationContext();
    const {userProfile} = useAppContext();
    const navigate = useNavigate();
    const [loadingSave, setLoadingSave] = useState(false);
    const {refresh} = useJobContext();

    const fieldMap = {
        jobName: 'job_name',
        jobRefCode: 'job_delivery_name',
        contactNumber: 'job_contact_number',
        description: 'job_description',
        dispatch: 'job_dispatch',
        address: 'job_address',
        city: 'job_city',
        street: 'job_address',
        suburb: 'job_suburb',
        postcode: 'job_postcode',
        state: 'job_country_State',
        client: 'job_client_id',
        files: 'job_attachments',
        depotId: 'depot_id',
        set_default_address: 'set_default_address',
        save_address: 'save_address',
        requestedDeliveryDate: 'requested_delivery_date',
    };

    const formData = new FormData();

    const jobFormSubmitHandler = async (values) => {
        setLoadingSave(true);
        Object.keys(fieldMap).forEach((key) => {
            if (key === 'files') {
                values[key].forEach((file) => {
                    formData.append(`${fieldMap[key]}[]`, file);
                });

                return;
            }
            formData.append(fieldMap[key], values[key]);
        });

        try {
            const response = await saveJob(formData, jobId);

            setLoadingSave(false);

            genericMessageHandler(
                notify,
                {message: response.messages},
                'success'
            );

            if (response.errors) {
                genericMessageHandler(notify, {message: response.errors});
            }

            if (response.hasOwnProperty('displayId')) {
                dispatch(invalidateProductAndCost());
                dispatch(invalidateDeliveryAddress());
                navigate(
                    getCreateJobRedirect(
                        userProfile,
                        response.displayId,
                        response.roomId
                    )
                );
                refresh({}); // update the job value in context
            }
        } catch (error) {
            setLoadingSave(false);
            genericMessageHandler(notify, [
                'Something went wrong while creating your job. Please trying logging out and logging back in.',
                error.message,
            ]);
        }
    };

    return {
        jobFormSubmitHandler,
        loadingSave,
    };
};

export const useJobListData = () => {
    const {status} = useParams();
    const [sortBy, setSortBy] = useState([9]);
    const {userProfile} = useAppContext();
    const [orderBy, setOrderBy] = useState('desc');
    const [order, setOrder] = useState({
        fields: [9],
        order: 'desc',
    });

    const {data: jobStatistics, isLoading} = useGetJobStatusStatisticsQuery();

    const allJobStatus = useMemo(() => {
        return [
            {id: 'all', url: 'all', text: 'All'},
            ...JobStatusFilter.filter(
                (status) => !jobStatistics || jobStatistics?.includes(status.id)
            ).map((filter) => {
                const filterFound = userProfile?.jobStatuses?.find(
                    (jobStatus) => filter.status === jobStatus.status
                );
                return {
                    ...filter,
                    text:
                        !!filterFound && filterFound?.custom_label
                            ? filterFound.custom_label
                            : JobStatus[filter.status],
                };
            }),
        ];
    }, [JobStatusFilter, jobStatistics, userProfile?.jobStatuses]);

    const selectedStatus = useMemo(() => {
        let selectedStatusOption = allJobStatus.find(
            (statusOption) => statusOption.url == status
        );

        if (!selectedStatusOption) {
            selectedStatusOption = allJobStatus[0];
        }

        return selectedStatusOption;
    }, [status, allJobStatus]);

    useEffect(() => {
        const newOrder = {...order};
        newOrder.fields = sortBy;
        newOrder.order = orderBy;
        setOrder(newOrder);
    }, [sortBy, orderBy]);

    return {
        allJobStatus,
        sortBy,
        orderBy,
        setOrderBy,
        setSortBy,
        selectedStatus,
        userProfile,
        isLoading,
    };
};

export const useJobSubmitHandler = () => {
    const [loader, setLoader] = useState(false);
    const {job, room, setJob, setRoom} = useJobContext();
    const {userProfile} = useAppContext();
    const navigate = useNavigate();
    const {showDialog, setMessage, hideDialog, setTitle, dialog} =
        useConfirmationDialog();
    const {notify} = useNotificationContext();

    const benches = useFetchBenchesData();
    const {refresh} = useJobContext();
    const dispatch = useAppDispatch();

    const pdfLink = useMemo(() => {
        const pdfLinkWithName = {
            link: `<a href="/api/job/${job.id}/pdf/details" target="_blank"> Job Properties PDF</a>`,
            name: 'job properties PDF',
        };
        if (
            userProfile.hasOwnProperty('showJobPropertiesTablePdf') &&
            userProfile.showJobPropertiesTablePdf &&
            userProfile.defaultJobPropertiesPdf == 2
        ) {
            pdfLinkWithName.link = `<a href="/api/job/${job.id}/pdf/details" target="_blank">Job Details PDF</a>`;
            pdfLinkWithName.name = 'job details PDF';
        } else if (
            userProfile.hasOwnProperty('showOrderAcknowledgementPdf') &&
            userProfile.showOrderAcknowledgementPdf &&
            userProfile.defaultJobPropertiesPdf == 3
        ) {
            pdfLinkWithName.link = `<a href="/quotePdf.php?jobId=${job.id}" target="_blank">Quote PDF</a>`;
            pdfLinkWithName.name = 'quote PDF';
        }
        return pdfLinkWithName;
    }, [userProfile, job]);

    const submitJobMessage = `<center>Before submitting, please validate the contents of the Job PDF.
                              <br/>
                              <center>${pdfLink.link}</center>
                              <br/>
                              Once submitted, you will be unable to make any changes to it.
                              <br/>
                              <br/>
                              By clicking <strong>Yes, I confirm that my ${pdfLink.name} is correct.</strong></center>`;

    const emptyRoomMessage = `It looks like one or more rooms in this job don't contain any products. To submit the job, these empty rooms must be removed. Would you like us to go ahead and delete them for you?`;

    const frightCostText = `NB/ The freight cost shown is just the minimum the manufacturer has set. In the event that the manufacturer decides a larger freight cost needs to be assigned, the job will be unsubmitted again for you to confirm the new cost.`;

    const jobSubmitHandlerSecondPrompt = async () => {
        // button.disabled = true;
        try {
            setLoader(true);

            if (job.totalProductCount < 1) {
                throw new Error('Job should have at least one product in it');
            }

            sendAnalyticsEvent('submitted', 'co_job');

            const response = await submitJob(job.id);
            response.map((m) =>
                genericMessageHandler(notify, {message: m}, 'success')
            );
            setJob({});
            setRoom({});
            navigate('/v2/');
            setLoader(false);
        } catch (error) {
            let errors = 'Error submitting the job. Please try again later.';

            // error should be an AxiosError, but we're going to be paranoid
            if ('response' in error) {
                if ('data' in error.response) {
                    if ('errors' in error.response.data) {
                        errors = error.response.data.errors;
                    } else if ('error' in error.response.data) {
                        errors = error.response.data.error;
                    }
                }
            } else if ('message' in error) {
                errors = error.message;
            }

            if (!Array.isArray(errors)) {
                errors = [errors];
            }

            // TODO: Show a popup with these errors instead
            errors.forEach((error) => {
                genericMessageHandler(notify, {message: error});
            });

            setLoader(false);
        }
    };

    const submitJobWithEmptyRoom = () => {
        showDialog({
            title: 'Delete empty rooms?',
            message: (
                <div style={{textAlign: 'center'}}>{emptyRoomMessage}</div>
            ),
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    show: true,
                    name: 'Yes',
                    controlledHideDialog: true,
                    variant: 'danger',
                    action: async () => {
                        setLoader(true);
                        hideDialog();
                        const roomsForDeletion = (job.rooms ?? []).filter(
                            (jobRoom) => jobRoom.productCount === 0
                        );

                        Promise.all(
                            roomsForDeletion.map((jobRoom) =>
                                deleteRoom(job.id, jobRoom.id)
                            )
                        ).then(() => {
                            refresh({});
                            dispatch(invalidateJob());
                            setLoader(false);
                            submitJobPdfDialog();
                        });
                    },
                },
                {
                    show: true,
                    name: 'No',
                },
            ],
        });
    };

    const submitJobRailsDialog = ({
        groupedCabinets,
        groupedRails,
        totalRailsAmountByExtColour,
        totalAmountByExtColour,
    }) => {
        showDialog({
            title: 'Recessed Handle Options Selected',
            message: `
            <div>
            <p>Your job contains less than the recommended amount of rails added for products which contain recessed handle options:</p>
            <table width="100%">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Product</th>
                        <th>Material</th>
                        <th>Rail Required</th>
                    </tr>
                </thead>

                <tbody>
                ${Object.values(groupedCabinets)
                    .map((cabinetGroup) => {
                        const rows = cabinetGroup
                            .map((item) => {
                                const railsForItem =
                                    (item.cabinet.doors > 0
                                        ? 1
                                        : item.drawers.length) * item.width;

                                const material = item.extMaterial;

                                return `
                                <tr>
                                    <td>${item.productNumber}</td>
                                    <td>${item.quantity} x ${item.typeName}</td>
                                    <td>${material.name} - ${
                                    material.thickness
                                }mm</td>
                                    <td>${railsForItem * item.quantity}mm</td>
                                </tr>`;
                            })
                            .join('');

                        return rows;
                    })
                    .join('')}
                </tbody>
            </table>
            <p><br/>${totalAmountByExtColour}</p>
            <p>Rails added to job:</p>

            ${
                Object.keys(groupedRails).length === 0
                    ? '<p><strong>NO RAILS HAVE BEEN ADDED</strong></p>'
                    : `
                        <table width="100%">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Rail</th>
                                    <th>Material</th>
                                    <th>Dimensions</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${Object.values(groupedRails)
                                    .map((railGroup) => {
                                        const rows = railGroup
                                            .map((item) => {
                                                const material =
                                                    item.extMaterial;
                                                return `
                                                <tr>
                                                    <td>${item.productNumber}</td>
                                                    <td>${item.quantity} x ${item.typeName}</td>
                                                    <td>${material.name} - ${material.thickness}mm</td>
                                                    <td>${item.dimensions}mm</td>
                                                </tr>`;
                                            })
                                            .join('');

                                        return rows;
                                    })
                                    .join('')}
                            </tbody>
                        </table>
            `
            }
            <p><br/>${totalRailsAmountByExtColour}</p>
        </div>
            `,
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    name: 'Back',
                    show: true,
                    action: () => {
                        hideDialog();
                    },
                },
                {
                    name: 'Submit Job Anyway',
                    show: true,
                    action: () => {
                        submitJobPdfDialog();
                    },
                },
                {
                    name: 'Add More Rails',
                    show: true,
                    variant: 'primary-color',
                    action: () => {
                        navigate(
                            `/v2/job/${job.displayId}/room/${room.id}/product?rails=true`
                        );
                    },
                },
            ],
        });
    };

    const submitJobPdfDialog = () => {
        let currentMessage = 0;

        showDialog({
            title: 'Show PDF',
            message: submitJobMessage,
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    show: true,
                    name: 'Yes',
                    controlledHideDialog: true,
                    variant: 'danger',
                    action: () => {
                        if (currentMessage == 0) {
                            let jobHasVariations = false;
                            let promptMessage = '';

                            // Checking if there are any variation requests in
                            // job cabinets
                            if (job && job.rooms) {
                                for (const room of job.rooms) {
                                    if (jobHasVariations) break;

                                    if (
                                        room?.jobCabinets &&
                                        Array.isArray(room.jobCabinets)
                                    ) {
                                        for (const jobCabinet of room.jobCabinets) {
                                            if (jobHasVariations) break;

                                            if (jobCabinet.comment != '') {
                                                jobHasVariations = true;
                                            }
                                        }
                                    }
                                }
                            }

                            // Checking if there are any variation requests in
                            // any of the benches
                            if (
                                benches &&
                                benches.length > 0 &&
                                !jobHasVariations
                            ) {
                                for (const bench of benches) {
                                    if (jobHasVariations) break;

                                    if (
                                        bench.comment != null &&
                                        bench?.comment != ''
                                    ) {
                                        jobHasVariations = true;
                                    }
                                }
                            }

                            if (jobHasVariations) {
                                if (job.variationsConfirmed == 0) {
                                    promptMessage = `${
                                        userProfile.jobSubmitTextWithVariations
                                    }${
                                        job.dispatchMethod != 0
                                            ? `<br />${frightCostText}`
                                            : ''
                                    }`;
                                } else {
                                    // variation confirmed by manufacturer
                                    const variationCost = formatPrice(
                                        job.totalVariationCost,
                                        job
                                    );
                                    const variationAcceptedText =
                                        userProfile.jobSubmitTextVariationsAccepted.replaceAll(
                                            /@variationCost/gi,
                                            variationCost
                                        );
                                    promptMessage = `${variationAcceptedText}`;
                                }
                            } else {
                                promptMessage = `${
                                    userProfile.jobSubmitTextNoVariations
                                } ${
                                    job.dispatchMethod != 0
                                        ? `<br />${frightCostText}`
                                        : ''
                                }`;
                            }

                            setMessage(`<center>${promptMessage}</center>`);
                            setTitle('Submit Job?');
                            currentMessage = 1;
                        } else {
                            hideDialog();
                            jobSubmitHandlerSecondPrompt();
                        }
                    },
                },
                {
                    show: true,
                    name: 'No',
                },
            ],
        });
    };

    const jobSubmitHandler = async () => {
        const {
            groupedCabinets,
            groupedRails,
            totalRailsAmountByExtColour,
            totalAmountByExtColour,
            lessRecessedHandle,
        } = await checkForRecessedRailsValidation();

        if (lessRecessedHandle) {
            submitJobRailsDialog({
                groupedCabinets,
                groupedRails,
                totalRailsAmountByExtColour,
                totalAmountByExtColour,
            });
        } else {
            const hasEmptyRoom = (job.rooms ?? []).some(
                ({productCount}: {productCount: number}) => productCount === 0
            );
            if (hasEmptyRoom) {
                submitJobWithEmptyRoom();
            } else {
                submitJobPdfDialog();
            }
        }
    };

    const checkForRecessedRailsValidation = async ({
        values = {},
        jobId = null,
    } = {}) => {
        const quickProducts = await getQuickProducts();
        const railsProducts = quickProducts.filter(
            (item) => item.isRecessedRail == 1 && item.hidden == 0
        );

        if (railsProducts.length == 0) {
            return {
                groupedCabinets: {},
                groupedRails: {},
                totalRailsAmountByExtColour: '',
                totalAmountByExtColour: '',
                lessRecessedHandle: false,
            };
        }

        const groupedCabinets = {};
        job?.rooms.forEach((room) => {
            room.jobCabinets.forEach((cabinet) => {
                const {fingerPull, extColour, type} = cabinet;
                if (fingerPull !== null) {
                    const key = `${extColour}-${type}`;
                    groupedCabinets[key] = groupedCabinets[key] || [];
                    groupedCabinets[key].push(cabinet);
                }
            });
        });

        const groupedRails = {};
        job?.rooms.forEach((room) => {
            room?.jobCabinets
                ?.filter((rail) => rail.cabinet.isRecessedRail)
                .forEach((rail) => {
                    const key = `${rail.extColour}`;
                    groupedRails[key] = groupedRails[key] || [];
                    groupedRails[key].push(rail);
                });
        });

        const totalProducts = {};
        Object.values(groupedCabinets).forEach((cabinetGroup) => {
            const extColour = cabinetGroup[0].extMaterial;
            const keyMaterial = `${extColour.name} - ${extColour.thickness}mm`;

            const totalExterior = cabinetGroup.reduce((total, item) => {
                const railsForItem =
                    (item.cabinet.doors > 0 ? 1 : item.drawers.length) *
                    item.width;
                return total + railsForItem * item.quantity;
            }, 0);

            if (totalProducts.hasOwnProperty(keyMaterial)) {
                totalProducts[keyMaterial] += totalExterior;
            } else {
                totalProducts[keyMaterial] = totalExterior;
            }
        });

        const totalAmountByExtColour = Object.entries(totalProducts)
            .map(
                ([extColour, totalRails]) => `
                Total for ${extColour} = ${totalRails}mm <br/>
            `
            )
            .join('');

        const dimensionValue = (item) => {
            if (item.dimensions.includes('x')) {
                const dimensionsArray = item.dimensions.split('x');
                if (dimensionsArray.length == 3) {
                    return item.width;
                }
            }

            return item.height;
        };

        const totalRailProducts = {};

        Object.values(groupedRails).forEach((railGroup) => {
            const extColour = railGroup[0].extMaterial;
            const keyMaterial = `${extColour.name} - ${extColour.thickness}mm`;
            const totalRails = railGroup.reduce((total, item) => {
                return total + dimensionValue(item) * item.quantity;
            }, 0);

            totalRailProducts[keyMaterial] = totalRails;
        });

        const totalRailsAmountByExtColour = Object.entries(totalRailProducts)
            .map(
                ([extColour, totalRails]) => `
                Total for ${extColour} = ${totalRails}mm <br/>
            `
            )
            .join('');

        let comparisonResult = {};
        for (const key in totalProducts) {
            if (totalRailProducts.hasOwnProperty(key)) {
                comparisonResult[key] =
                    totalRailProducts[key] >= totalProducts[key];
            } else {
                comparisonResult[key] = false;
            }
        }

        const lessRecessedHandle =
            Object.values(comparisonResult).includes(false);

        comparisonResult = {};

        return {
            groupedCabinets,
            groupedRails,
            totalRailsAmountByExtColour,
            totalAmountByExtColour,
            lessRecessedHandle,
        };
    };

    return {
        checkForRecessedRailsValidation,
        loader,
        jobSubmitHandler,
        dialog,
        showJobDialog: showDialog,
    };
};
